import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import * as CryptoJS from 'crypto-js'

const AccountDetail = (p) => {
    const history = useNavigate()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const secretKey = 't8HmAA8wt4JWE5WA0C8jPhK51ROJNepzwsL'

    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`account`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    };

    const decriptData = (data) => {
        if (data) {
            try {
                const bytes = CryptoJS.AES.decrypt(data, secretKey);
                const plainText = bytes.toString(CryptoJS.enc.Utf8);
                const sanitizedText = plainText.replace(/"/g, '');
            return sanitizedText;
            } catch (error) {
                console.error("Decryption error:", error);
                return null;
            }
        }
    }
    

    const back = () => {
        history(-1)
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
    }, [id, userId])

    return (
        <Layout>








     
                <div>
                    <a class="users_back" to="/users" onClick={back}>  <i className="fa fa-arrow-left ml-1 mr-2 " title='Back' aria-hidden="true"></i>Account Detail</a>
                </div>
        
            <div className="row mt-4">
                <div className="sideclass col-md-12">
              <div className='pprofile1 '>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Name</label>
                            <div className='profiledetailscls'>{data && data.name}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Account Id</label>
                            <div className='profiledetailscls'>{decriptData(data && data.account_id)}</div>
                        </div>
                        {/* <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Customer Id</label>
                            <div className='profiledetailscls'>{decriptData(data && data.customerId)}</div>
                        </div> */}
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Account Number</label>
                            <div className='profiledetailscls'>{decriptData(data && data.realAccountNumber)}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Type</label>
                            <div className='profiledetailscls'>{data && data.type}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Market Segment</label>
                            <div className='profiledetailscls'>{data && data.marketSegment}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Status</label>
                            <div className='profiledetailscls'>{data && data.status}</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </Layout>

    );
};

export default AccountDetail;
