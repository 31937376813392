import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import Item from "antd/es/list/Item";
 

const Html = ({
  view,
  edit,
  reset,
  add, 
  tab, 
  statusChange,
  pageChange,
  deleteItem, 
  filters,
  loaging,
  data, 
  decrypt,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  total = { total },
}) => {

  const [DeleteId, setDeleteId] = useState("");
  const[userid,setuserid] = useState("")
  const Delete = () => {
    deleteItem(DeleteId,userid);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };
  return (
    <Layout>
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        confirm={StatusCh}
        status={StatusData.status}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Cardpointe Credentials</h3>

        <article className="d-flex filterFlex phView"> 
        
              <a className="btn btn-primary mr-2" onClick={(e) => add()}>
                Add cardpointe Credential
              </a>
            
          
          {filters.status || filters.role ? (
            <>
              <a
                className="btn btn-danger text-white mr-2"
                onClick={(e) => reset()}
              >
                Reset
              </a>
            </>
          ) : (
            <></>
          )}
        </article>
      </div>

      {tab == "grid" ? (
        <>
          <div className="cardList">
            <div className="row">
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      <div className="new_cards">
                        <div className="user_card">
                          <div
                            className="user_detail"
                            onClick={(e) => view(itm.id)}
                          >
                            <img
                              src={methodModel.userImg(itm.image)}
                              className="user_imgs"
                            />

                            <div className="user_name">
                              <h4 className="user">{itm.fullName}</h4>
                              <p className="user_info">{itm.email}</p>
                            </div>
                          </div>

                          <div
                            className={`user_hours ${itm.status}`}
                            onClick={() => statusChange(itm)}
                          >
                            <span className="contract">{itm.status}</span>
                          </div>
                        </div>

                        <div className="user_proff user_proff1">
                          <div className="id_name">
                            <ul className="user_list">
                              <li className="list_name">
                                <a className="id">Role</a>
                              </li>
                              <li className="list_name">
                                <a className="id">Phone number</a>
                              </li>
                            </ul>
                          </div>
                          <div className="detail_list">
                            <ul className="user_list">
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  {itm.role?.name}
                                </a>
                              </li>
                              <li className="list_names">
                                <a
                                  className="id_name"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <span className="call_icon"></span>
                                  {itm.mobileNo ? (
                                    <>
                                      <i
                                        class="fa fa-phone"
                                        aria-hidden="true"
                                      ></i>
                                      {itm.dialCode} {itm.mobileNo}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="table-responsive table_section">
            <table class="table">
              <thead className="table_head">
                <tr className="heading_row"> 
                <th scope="col" className="table_data">
                  User Name
                  </th>
                  <th scope="col" className="table_data">
                    Mode
                  </th>
                  <th
                    scope="col"
                    className="table_data"
                  
                  >
                    Site
                  </th>
                  <th
                    scope="col"
                    className="table_data"
                  >
                    Currency
                  </th>
                  <th
                    scope="col"
                    className="table_data"
                  >
                   Api User Name
                  </th>
                  <th scope="col" className="table_data">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  data &&
                  data.map((itm, i) => {
                    return (
                      <tr className="data_row">
                      
                        <td
                          className="table_dats"
                          style={{ textTransform: "lowercase" }}
                        >
                          {itm.fullName || "N/A"}
                        </td>
                        <td
                          className="table_dats"
                          style={{ textTransform: "lowercase" }}
                        >
                          {itm.mode}
                        </td>
                        <td
                          className="table_dats"
                          style={{ textTransform: "lowercase" }}
                        >
                          {decrypt(itm.site)}
                        </td>
                        <td className="table_dats">
                          {decrypt(itm?.currency)}
                        </td>
                        <td className="table_dats">
                          {decrypt(itm?.authuname)}
                        </td>

                        {/* dropdown */}
                        <td className="table_dats">
                          <div className="action_icons">
                            {isAllow("editAdmins") ? (
                              <>
                                <a
                                  className="edit_icon"
                                  title="Edit"
                                  onClick={(e) => edit(itm.id,itm?.user_id)}
                                >
                                  <i class="material-icons edit" title="Edit">
                                    edit
                                  </i>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}

                            {isAllow("deleteAdmins") ? (
                              <>
                                <span
                                  className="edit_icon"
                                  title="Delete"
                                  onClick={() => {
                                    setDeleteId(itm.id);
                                    setuserid(itm?.user_id)
                                    setShowDeleteModal("block");
                                  }}
                                >
                                  <i
                                    class="material-icons delete"
                                    title="Delete"
                                  >
                                    {" "}
                                    delete
                                  </i>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                        
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!loaging && data?.length == 0 ? (
              <div className="py-3 text-center">No Data</div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} Users
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
