import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import datepipeModel from "../../models/datepipemodel";
import RefundModal from "../../models/refund.modal";
import SelectDropdown from "../../components/common/SelectDropdown";
import TransferModal from "../../models/transfermoneymodel";
import Item from "antd/es/list/Item";

const Html = ({
  pageChange,
  filters,
  loaging,
  data,
  history,
  changestatus,
  setRefundid,
  ShowRefundModel,
  setShowRefundModal,
  refund,
  handleTransfer,
  user,
  setRetRef,
  decryptData,
  total = { total },
  statuses,
  clear,
  showTransfer,
  setTransferShow,
  formData,
  setFormData,
  transferStatusChange,
  tranferData,
  setTranferData,
  changeMerchant,
  allMerchants,
  setRejectData,
  setRejectionModal,
}) => {
  return (
    <Layout>
      <RefundModal
        show={ShowRefundModel}
        setShow={setShowRefundModal}
        confirm={refund}
      />

      <TransferModal
        showTransfer={showTransfer}
        setTransferShow={setTransferShow}
        confirm={handleTransfer}
        formData={formData}
        setFormData={setFormData}
      />

      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Transactions</h3>
        <div className="d-flex gap-2 ml-auto">
          <SelectDropdown
            id="statusDropdown"
            displayValue="name"
            placeholder="All Status"
            intialValue={filters.status}
            result={(e) => {
              changestatus(e.value);
            }}
            options={statuses}
          />
          <SelectDropdown
            id="statusDropdown"
            displayValue="fullName"
            placeholder="All Merchants"
            intialValue={filters.merchant_id}
            result={(e) => {
              changeMerchant(e.value);
            }}
            options={allMerchants}
          />
          {filters.status || filters.merchant_id ? (
            <>
              <button className="btn btn-secondary" onClick={() => clear()}>
                {/* <i class="fa fa-repeat" aria-hidden="true"></i> */}
                Reset
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <>
        <div className="table-responsive table_section">
          <table className="table">
            <thead className="table_head">
              <tr className="heading_row">
                <th scope="col" className="table_data">
                  Merchant Name
                </th>

                <th scope="col" className="table_data">
                  Customer Name
                </th>

                <th scope="col" className="table_data">
                  Amount($)
                </th>
                <th scope="col" className="table_data">
                  Commision($)
                </th>
                <th scope="col" className="table_data">
                  Transfer Amount($)
                </th>
                <th scope="col" className="table_data">
                  Payment Status
                </th>
                <th scope="col" className="table_data">
                  Order Date
                </th>
                <th scope="col" className="table_data">
                  Transaction Id
                </th>
                {user.role_name != "Customer" ? (
                  <th scope="col" className="table_data">
                    Actions
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td
                        onClick={(e) =>
                          history(`/transactionDetail/${itm?.id}`)
                        }
                        className="table_dats"
                      >
                        {itm?.merchant_name || "--"}
                      </td>
                      <td className="table_dats">
                        {itm?.customer_name || "--"}
                      </td>
                      <td className="table_dats">
                        {itm?.amount
                          ? (decryptData(itm.amount) * 100).toFixed(2)
                          : "--"}
                      </td>
                      <td className="table_dats">{itm?.commision || "--"}</td>
                      <td className="table_dats">
                        {" "}
                        {parseFloat(itm?.transfer_amount).toFixed(2) || "--"}
                      </td>
                      <td className="table_dats">
                        <div className={`user_hours ${itm.status}`}>
                          <span className="contract">{itm.status}</span>
                        </div>
                      </td>
                      <td className="table_dats">
                        {datepipeModel.date(itm.createdAt)}
                      </td>
                      <td className="table_dats">
                        <span className="tableElipsis">
                          {itm?.transaction_id || "--"}
                        </span>
                      </td>

                       {itm?.status == "Approval" ? 
                        <td className="table_dats ">
                          <div className="multipleBtns d-flex border-0">
                            {!itm?.isRefund && (
                              <>
                                {!itm?.refundStatus ? (
                                  ""
                                ) : itm?.refundStatus == "approved" ? (
                                  <button
                                    disabled={
                                      itm?.isRefund == true &&
                                      user.role_name != "Customer"
                                    }
                                    onClick={() => {
                                      setRefundid(itm.id);
                                      setShowRefundModal("block");
                                      setRetRef(itm?.retref);
                                    }}
                                    className="btn btn-primary ml-1  removeBg"
                                    title="Refund"
                                  >
                                    
                                    <img src="/assets/img/dol.png" alt="" />
                                  </button>
                                ) : itm?.refundStatus == "rejected" ? (
                                  <button  className="btn btn-danger ml-1 reject-btn ">
                                    Rejected
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-success text-white ml-1  removeBg"
                                      title="Approve"
                                      onClick={() =>
                                        transferStatusChange(itm, "approved")
                                      }
                                    >
                                      {/* <img src="assets/img/tick.png" /> */}
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      className="btn btn-danger ml-1  removeBg"
                                      title="Reject"
                                      onClick={() => {
                                        transferStatusChange(itm, "rejected");
                                        setRejectData(itm);
                                      }}
                                    >
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                            {!itm?.isRefund &&
                            (itm?.status == "Approval" && !itm?.transferAt) ? (
                              <button
                                onClick={() => {
                                  setTranferData(itm);
                                  setTransferShow("block");
                                }}
                                className="btn btn-primary ml-1  removeBg"
                                title="Transfer"
                              >
                                {/* Transfer */}
                                <img src="/assets/img/mr.png" alt="" />
                              </button>
                            ) : itm?.transferAt ? (
                              <button className="btn btn-success text-white ml-1  removeBg">
                                Transfered
                              </button>
                            ) : itm?.status != "Approval" &&
                              !itm?.refundStatus ? (
                              <div className="ml-3">--</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      : ""} 
                      
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {!loaging && total == 0 ? (
            <div className="py-3 text-center">No Data</div>
          ) : (
            <></>
          )}
        </div>
      </>

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} transactions
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
