import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import "./style.scss";
import SelectDropdown from "../../components/common/SelectDropdown";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import { DatePicker } from "antd";
import loader from "../../methods/loader";
import environment from "../../environment";
import CryptoJS from "crypto-js";
import BarGraph from "../../components/bargraph";
import WaveGraph from "../../components/wavegraph";
import SalesAreaChart from "../../components/salesgraph";

const Dashboard = () => {
  const Navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState();
  const [dates, setDates] = useState([null, null]);
  const [data, setData] = useState();
  const [commisionData, setCommisiondata] = useState([]);
  const[refunddata,setRefundData] = useState([])
  const[salesData,setSalesData] = useState([])
  const [aggregation, setAggregation] = useState("monthly");
  const[refundAgree,setRefundAgree] = useState("monthly")
  const[salesAgree,setsalesAgree] = useState("monthly")
  const { RangePicker } = DatePicker;

  const handleClick = () => {
    if (localStorage.getItem("token")) {
      ApiClient.get("mastercard/connect-url").then((res) => {
        const url = res?.data;
        window.open(url);
      });
    }
  };

  useEffect(() => {
    if (user?._id || user?.id) {
      ApiClient.get(`user/profile`, { id: user._id || user?.id }).then(
        (res) => {
          if (res.success) {
            if (
              res?.data?.role_name != "Customer" &&
              res?.data?.role_name != "Guest" &&
              res?.data?.active_accounts <= 0
            ) {
              setIsModalOpen(true);
            }
            return () => {
              setIsModalOpen(false);
            };
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    getTransactionsData();
  }, []);

  useEffect(() => {
    getCommisionGraphData();
  }, [aggregation]);

  useEffect(()=>{
  getRefundGraphdata()
  },[refundAgree])

  useEffect(()=>{
 getsalesGraphdata()
  },[salesAgree])

  const list = [
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" },
    { id: "yearly", name: "Yearly" },
  ];

   const getsalesGraphdata =()=>{
    loader(true);
    ApiClient.get(
      `sale/graph?mode=sandbox&granularity=${salesAgree}`
    ).then((res) => {
      if (res?.success) {
        loader(false);
        setSalesData(res?.data);
      }
    });
   }

  const getTransactionsData = (updatedFilters) => {
    loader(true);
    let filter = {
      ...updatedFilters,
      type: "cart",
    };
    ApiClient.get("admin/analytics", filter).then((res) => {
      if (res?.success) {
        loader(false);
        setData(res?.data);
      }
    });
  };

  const getCommisionGraphData = () => {
    loader(true);
    ApiClient.get(
      `admin/comission?mode=sandbox&granularity=${aggregation}`
    ).then((res) => {
      if (res?.success) {
        loader(false);
        setCommisiondata(res?.data);
      }
    });
  };

   const getRefundGraphdata =()=>{
    loader(true);
    ApiClient.get(
      `refund/graph?mode=sandbox&granularity=${refundAgree}`
    ).then((res) => {
      if (res?.success) {
        loader(false);
        setRefundData(res?.data);
      }
    });
   }
  const onChange = (dates, dateStrings) => {
    setDates(dateStrings);
    const updatedFilters = {
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    };
    setFilters(updatedFilters);
    getTransactionsData(updatedFilters);
  };
  const changestatus = (e) => {
    setAggregation(e);
  };

  return (
    <Layout>
      <div className="row d-flex align-items-center">
        <div className="col-md-9 head_cls">
          <h2 className="mb-1 mainHeading_cls">
            {" "}
            <span className="dsh_hedding">Hi</span>
            {user && user.fullName}
          </h2>
          <p className="paraCls">
            Here’s what’s going on with your team Designspace
          </p>
        </div>

        <div className="col-md-3 text-right d-flex justify-content-end">
          <RangePicker format="YYYY-MM-DD" onChange={onChange} />
        </div>

        <div className="cards_sales">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div
                  className="main_sales"
                  onClick={(e) => Navigate(`/transaction`)}
                >
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Sales</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      ${" "}
                      {parseFloat(data?.totalTransactionAmount).toFixed(2) ||
                        "--"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Captured Amount</p>
                    </div>
                    <div>
                      <img
                        src="/assets/img/wallet.png"
                        className="wallets_img"
                      />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      $ {parseFloat(data?.captured_amount).toFixed(2) || "--"}
                    </h3>
                  </div>
                </div>
              </div>
            </div> 
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pointer"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">No. of Transactions:</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {data?.transaction_volume || "0"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pointer"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Commission earned:</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      ${" "}
                      {parseFloat(data?.commissions_earned).toFixed(2) || "--"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pointer"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Transfer Amount:</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      $ {parseFloat(data?.transfer_amount).toFixed(2) || "--"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pointer"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">No. of Refunds:</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      {data?.transaction_refunds || "0"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pointer"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Refunded Amount:</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      ${" "}
                      {parseFloat(data?.transaction_refunds_amount).toFixed(
                        2
                      ) || "--"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 pointer"
              onClick={(e) => Navigate(`/transaction`)}
            >
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Queued Amount :</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">
                      $ {parseFloat(data?.queued_amount).toFixed(2) || "--"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* graph Design */}
          <div className="row"> 
            <div className="col-md-12">
              <div className="d-flex align-items-center mt-3 justify-content-between">
                <h5 className="sales mb-0  ">Commission</h5>
                <div className="">
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name" 
                    theme="search"
                    intialValue={aggregation}
                    isClearable={false}
                    result={(e) => {
                      changestatus(e.value);
                    }}
                    options={list}
                  />
                </div>
              </div>
              <div className="chatr_ones border border-gray-200 p-6 mt-2 rounded-lg">
                <WaveGraph data={commisionData} period={aggregation} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex align-items-center mt-3 justify-content-between">
                <h5 className="sales mb-0  ">Refunds</h5>
                <div className="">
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    theme={"search"}
                    intialValue={refundAgree}
                    isClearable={false}
                    result={(e) => {
                      setRefundAgree(e.value);
                    }}
                    options={list}
                  />
                </div>
              </div>
              <div className="chatr_ones border border-gray-200 p-6 mt-2 rounded-lg">
                <BarGraph data={refunddata} period={refundAgree} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex align-items-center mt-3 justify-content-between">
                <h5 className="sales mb-0  ">Sales </h5>
                <div className="">
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    theme={"search"}
                    intialValue={salesAgree}
                    isClearable={false}
                    result={(e) => {
                      setsalesAgree(e.value);
                    }}
                    options={list}
                  />
                </div>
              </div>
              <div className="chatr_ones border border-gray-200 p-6 mt-2 rounded-lg">
                <SalesAreaChart data={salesData} period={salesAgree} />
              </div>
            </div>
          </div>
        </div>
        <div class="onload-modal">
          {isModalOpen && (
            <div className="modal1">
              <div className="bank-modal">
                <div className="position-relative">
                  <span
                    className="close-icon"
                    onClick={() => setIsModalOpen(false)}
                  >
                    &times;
                  </span>
                </div>
                {(user?.role_name != "Customer" ||
                  ("Guest" && user?.active_accounts <= 0)) && (
                  <div className="bg-white-modal">
                    <img src="assets/img/logo.png" />
                    <button
                      onClick={() => {
                        // open()
                        handleClick();
                        setIsModalOpen(false);
                      }}
                      className="dark-btn w-100 bank-modal-btn"
                    >
                      Connect a Bank Account
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
