import React, { useEffect, useState } from "react";

export default function TransferModal({ showTransfer, confirm, setTransferShow, initialData, formData, setFormData }) {
  const [showModal, setShowModal] = useState("none");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setShowModal(showTransfer);

    if (initialData) {
      setFormData(initialData);
    }
  }, [showTransfer, initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.accountNumber) newErrors.accountNumber = "Account number is required";
    if (!formData.routingNumber) newErrors.routingNumber = "Routing number is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.postalCode) newErrors.postalCode = "Postal code is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleConfirm = () => {
    if (validateForm()) {
      confirm(); 
      setTransferShow("none");
    }
  };

  return (
    <div>
      <div
        className={`modal delete-modal d-${showTransfer}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-0">
            <div className="modal-header">
              <h5 className="text-center">Transfer Details</h5>
              <button
                type="button"
                className="close"
                onClick={() => setTransferShow("none")}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body p-4 text-left">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Account Number</label>
                  <input
                    type="text"
                    className={`form-control ${errors.accountNumber ? "is-invalid" : ""}`}
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                    placeholder="Enter account number"
                  />
                  {errors.accountNumber && <div className="invalid-feedback">{errors.accountNumber}</div>}
                </div>
                <div className="col-md-6 form-group">
                  <label>Routing Number</label>
                  <input
                    type="text"
                    className={`form-control ${errors.routingNumber ? "is-invalid" : ""}`}
                    name="routingNumber"
                    value={formData.routingNumber}
                    onChange={handleInputChange}
                    placeholder="Enter routing number"
                  />
                  {errors.routingNumber && <div className="invalid-feedback">{errors.routingNumber}</div>}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    className={`form-control ${errors.address ? "is-invalid" : ""}`}
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Enter address"
                  />
                  {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                </div>
                <div className="col-md-6 form-group">
                  <label>City</label>
                  <input
                    type="text"
                    className={`form-control ${errors.city ? "is-invalid" : ""}`}
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    placeholder="Enter city"
                  />
                  {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className={`form-control ${errors.country ? "is-invalid" : ""}`}
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    placeholder="Enter country"
                  />
                  {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                </div>
                <div className="col-md-6 form-group">
                  <label>Postal Code</label>
                  <input
                    type="text"
                    className={`form-control ${errors.postalCode ? "is-invalid" : ""}`}
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    placeholder="Enter postal code"
                  />
                  {errors.postalCode && <div className="invalid-feedback">{errors.postalCode}</div>}
                </div>
              </div>
            </div>

            <div className="modal-footer text-center p-2">
              <button
                type="button"
                onClick={() => setTransferShow("none")}
                className="btn btn-secondary mr-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleConfirm}
                className="btn btn-primary"
              >
                Transfer Money
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
