import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/global/layout";  
import "./style.scss";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import environment from "../../../environment";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";


const ViewContactUs = (p) => {
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getDetail(id);
  }, [id]);
  const getDetail = (did) => {
    loader(true);
    let url = `contact?id=${id}`;
    ApiClient.get(url).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history(-1)
  };
  return (
    <Layout>
      <div className="text-left">
        <div>
          <a className="back_arrow" to="/users" onClick={back}>
            {" "}
            <i
              className="fa fa-arrow-left mr-2 mb-3 left_arrow "
              title="Back"
              aria-hidden="true"
            ></i>{" "}
            Back{" "}
          </a>
        </div>
      </div>
      <div className="transaction_details white_bx">
        <h3 className=" main_transcation py-3">Contact us Detail</h3> 

       <div className="table table-responsive"> <table class="table ">
          <thead>
            <tr>
              {/* <th scope="col"></th> */}
              <th scope="col">
                <label className=" table_heading ">Name</label>
              </th>
              <th scope="col">
                <label className=" table_heading ">Email</label>
              </th>
              <th scope="col">
                <label className=" table_heading ">Subject</label>
              </th>
              <th scope="col">
                {" "}
                <label className=" table_heading ">Message</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <th scope="row"></th> */}
              <td>
                <div className="table_data ">
                  {data && data?.name}
                </div>
              </td>
              <td>
                <div className="table_data ">
                  <span className="test-rev">{data && data?.email}</span>
                </div>
              </td>
              <td>
                <div className="table_data ">{data && data?.title}</div>
              </td>
              <td>
                <div className="table_data ">{data && data?.message}</div>
              </td>
            </tr>
        
          </tbody>
        </table></div>
      </div>
    </Layout>
  );
};

export default ViewContactUs;
