import React, { useState, useEffect } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Html from "./html";
import CryptoJS from "crypto-js";
import environment from "../../../environment";

const AddEditDevice = () => {
  const { role, id, marchid } = useParams();
  const Modes = useSelector((state) => state.user);
  const [images, setImages] = useState([]); 
  const [devices, setDevices] = useState([{ hsn: "", merchid: "", user_id: "",deviceName: "" }]);
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useNavigate();
  const [category, setCategory] = useState([]);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [ChangeStatus, setChangeStatus] = useState("");
  const [detail, setDetail] = useState();
  const [merchants,setMerchants] = useState([])

  const getError = (key) => {
    // return methodModel.getError(key, form, formValidation)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    let method = "post";
    let url = "devices";
    let value = {  devices: devices.map(device => ({
      hsn: device.hsn,
      merchid: device.merchid,
      user_id: device.user_id  ,
      deviceName: device?.deviceName
    }))};
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        let url = "/devices"; 
        history(url);
      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    setImages(e?.value);
  };

  const addressResult = (e) => {
    setform((state) => {
      return { ...state, address: e.value };
    });
  };

  const back = () => {
    history(-1);
  };
  function prepareEncryptedData(form, fieldsToEncrypt) {
    const encryptedData = { ...form, user_id: marchid, id: id };
    fieldsToEncrypt.forEach((field) => {
      if (encryptedData[field] !== undefined) {
        encryptedData[field] = encryptValue(encryptedData[field]);
      }
    });

    return encryptedData;
  }

  function encryptValue(value) {
    return CryptoJS.AES.encrypt(value, environment?.secretKey).toString();
  }

  const emailCheck = (email) => {
    let isValid = methodModel.emailvalidation(email);
    if (isValid) {
      setEmailLoader(true);
      ApiClient.get("check/Email", { email: email }).then((res) => {
        if (!res.success) {
          if (detail?.email != email) {
            setEmailErr(res.error.message);
          }
        } else {
          setEmailErr("");
        }
        setEmailLoader(false);
      });
    }
  };

  useEffect(() => {
    getMerchants();
  }, []);

  const getMerchants = () => {
  loader(true) 
    let url = "users/listing?page=1&count=70&search=&role=6540cfa35a7513892be61415&isDeleted=false"
    ApiClient.get(url).then((res) => {
     if(res?.success){
        loader(false)
  setMerchants(res?.data)
     }
    });
  };
  useEffect(() => {
    setSubmitted(false);
    if (id) {
      loader(true);
      ApiClient.get(`creds/${id}`).then((res) => {
        if (res.success) {
          setDetail(res?.data);
          setform({
            mode: res?.data?.mode || "",
            site: res?.data?.site ? decrypt(res?.data?.site) : "",
            merchid: res?.data?.merchid ? decrypt(res?.data?.merchid) : "",
            authuname: res?.data?.authuname
              ? decrypt(res?.data?.authuname)
              : "",
            isAch: res?.data?.isAch || false,
            currency: res?.data?.currency ? decrypt(res?.data?.currency) : "",
            authpass: res?.data?.authpass ? decrypt(res?.data?.authpass) : "",
          });
        }
        loader(false);
      });
    }
  }, [id]);

  function decrypt(encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, environment?.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }


  

  return (
    <>
      <Html 
        detail={detail}
        emailCheck={emailCheck}
        emailLoader={emailLoader}
        emailErr={emailErr}
        ChangeStatu={ChangeStatus}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        role={role} 
        category={category}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit} 
        getError={getError}
        id={id}
        history={history} 
        merchants={merchants}
        devices={devices}
        setDevices={setDevices}
      />
    </>
  );
};

export default AddEditDevice;
