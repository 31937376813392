import React, { useEffect, useState } from "react";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import ImageUpload from "../../../components/common/ImageUpload";
import methodModel from "../../../methods/methods";
// import methodModel from "../../methods/methods";

export default function AddEditcategory() {
  const [images, setImages] = useState({ image: '', banner: '', icon: '' });
  const defaultvalue = {
    id: "",
    name: "",
    catType: "",
    subParentCategory: "",
    image: "",
    parentCategory: ""
  };
  const { id, type } = useParams();
  const [form, setform] = useState({ defaultvalue, catType: type ? type : "" });
  const [categories, setCategories] = useState([]);
  const [tab, setTab] = useState("info");
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [Types, setTypes] = useState();
  const user = useSelector((state) => state.user);
  const formValidation = [
    { key: "name", required: true },
    { key: "catType", required: true },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (tab == "seo" && invalid) {
      toast.error("Please fill Required Fields");
    }

    if (invalid) return;
    let method = "post";
    let url = "category";
    let value = {
      ...form,
      ...images,
      subParentCategory: CategoryChange(form.parentCategory),
      parentCategory: form.parentCategory ? form.parentCategory : null,
    };
    if (value.id) {
      method = "put";
      url = "category/update";
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        // if (type) {
        //     history(`/category/${type}`)
        // } else {
        //     history("/categories")
        // }
        history("/categorylist");
      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
    setform({ ...form, image: e.value }); 
  };

  const getCategory = (t = type) => {
    ApiClient.get("categories/listing", {
      catType: t,
      status: "active",
      type: "main",
    }).then((res) => {
      if (res.success) {
        setCategories(res.data);
      }
    });
  };

  const CategoryChange = (e) => {
    let ext = categories.find((itm) => itm.id == e);
    let value = null;
    if (ext && ext.parentCategory) value = ext.parentCategory._id;
    return value;
  };

  const back = () => {
    history(-1);
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get("category/detail", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = defaultvalue;
          let oarr = Object.keys(defaultvalue);
          oarr.map((itm) => {
            payload[itm] = value[itm] || "";
          });
          if (payload.parentCategory)
            payload.parentCategory = payload.parentCategory._id;
          setform({
            ...payload,
            // tiny_bannerOverlayBody: payload.bannerOverlayBody,
            // tiny_description: payload.description,
          });
          Object.keys(images).map((itm) => {
            images[itm] = value?.[itm];
          });
          setImages(images);
        }
        loader(false);
      });
    } else {
      let payload = defaultvalue;
      Object.keys(defaultvalue).map((itm) => {
        payload[itm] = "";
      });
      if (type) payload.catType = type;
      payload.status = "active";
      payload.featured = "No";
      setform(payload);
    }
    getCategory();
  }, [id]);

  // const getTypes=()=>{
  //     ApiClient.get(`api/categorytype/listing`,{status:'active'}).then(res=>{
  //         if(res.success){
  //             setTypes(res.data)
  //         }
  //     })
  // }
  // useEffect(()=>{
  //     getTypes();
  // },[])

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
        <h3 className="ViewUser mb-3">
              {form && form.id ? "Edit" : "Add"} {type ? "Reseller" : ""}{" "}
              Category
            </h3>
          <div className="pprofile1 edit_profile">
          

            <div className="form-row">
              {/* <div className="col-md-12 mb-3">
                          <a className={`btn ${tab == 'info' ? 'btn-primary cat' : 'btn-light list'} mr-3`} onClick={e => setTab('info')}>Information</a>
                          <a className={`btn ${tab == 'seo' ? 'btn-primary cat' : 'btn-light list'} mr-3 ml-3`} onClick={e => setTab('seo')}>SEO</a>
                      </div> */}

              {tab == "info" ? (
                <>
                  <div className="col-md-6 mb-3">
                    <label>
                      Name<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.name}
                      onChange={(e) =>
                        setform({ ...form, name: e.target.value })
                      }
                      required
                    />
                  </div>

                  <div className="col-md-3 col-6 rolebtn">
                    <div className="">
                      {!type ? (
                        <div className="">
                          <label>
                            Type<span className="star">*</span>
                          </label>
                          <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="Select Type"
                            intialValue={form.catType}
                            result={(e) => {
                              setform({
                                ...form,
                                catType: e.value,
                                parentCategory: "",
                              });
                              getCategory(e.value);
                            }}
                            options={[
                              { id: "page", name: "Page" },
                              { id: "faq", name: "faq" },
                              { id: "products", name: "products" },
                            ]}
                          />
                          {submitted && !form.catType ? (
                            <div className="text-danger">Type is Required</div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
 </div>
 </div>
                      <div className="col-md-3 col-6 rolebtn">
                        <label>Parent Category</label>
                        <SelectDropdown
                          id="statusDropdown"
                          displayValue="name"
                          placeholder="Set as Parent"
                          intialValue={form.parentCategory}
                          result={(e) => {
                            setform({ ...form, parentCategory: e.value });
                          }}
                          options={categories}
                        />
                      </div>
                   
                 

                  {/* <div className="col-md-6 mb-3">
                              <label>Order</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  value={form.order}
                                  maxLength="10"
                                  onChange={e => setform({ ...form, order: methodModel.isNumber(e) })}
                              />
                          </div> */}

                  {/* <div className="col-md-6 mb-3">
                              <label>Status<span className="star">*</span></label>
                              <SelectDropdown
                                  id="statusDropdown"
                                  displayValue="name"
                                  placeholder="Select Status"
                                  intialValue={form.status}
                                  result={e => { setform({ ...form, status: e.value }) }}
                                  options={statusModel.list}
                              />
                              {submitted && !form.status ? <div className="text-danger">Status is Required</div> : <></>}
                          </div> */}

                  <div className="col-md-6  mb-3">
                    <label className="lablefontcls">Image</label>
                    <br></br>
                    <ImageUpload
                      model="users"
                      result={(e) => imageResult(e, "image")}
                      value={images.image || form.image}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-12 mb-3">
                    <label>URL Key</label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.urlKey}
                      onChange={(e) =>
                        setform({ ...form, urlKey: e.target.value })
                      }
                    />
                    <div className="small">Leave empty to auto generate</div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>
                      Meta Title<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.metaTitle}
                      onChange={(e) =>
                        setform({ ...form, metaTitle: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>
                      Meta Description<span className="star">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      value={form.metaDescription}
                      onChange={(e) =>
                        setform({ ...form, metaDescription: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>
                      Keywords<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.keywords}
                      onChange={(e) =>
                        setform({ ...form, keywords: e.target.value })
                      }
                      required
                    />
                  </div>
                </>
              )}
            </div>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary mr-2 discard"
                onClick={(e) => back()}
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
}
