import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; 
import ApiClient from "../methods/api/apiClient";
import loader from "../methods/loader";

export default function EditDeviceModal({ show, confirm, setShow, id,hide,setHide }) {
  const [showModal, setShowModal] = useState("none");
  const [device, setDevice] = useState({ hsn: "", merchid: "", user_id: "" });
  const [merchants, setMerchants] = useState([]);
  const history = useNavigate();
  const [data, setData] = useState();

  const getDetail = () => {
    loader(true);
    ApiClient.get(`device`, { id: id }).then((res) => {
      if (res.success) {
        setDevice({
          ...device,
          hsn: res?.data?.hsn,
          user_id: res?.data?.user_id?._id,
          merchid: res?.data?.merchid,
          deviceName: res?.data?.deviceName,
        });
      }
      loader(false);
    });
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleDeviceChange = (field, value) => {
    setDevice((prevDevice) => ({
      ...prevDevice,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const value = {  
          hsn: device.hsn,
          merchid: device.merchid,
          user_id: device.user_id,
          deviceName: device?.deviceName,
          id : id
        }
  
    const method = "put";
    const url = "device";
    loader(true); 
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setShow("none")
        setHide(!hide)
        history("/devices");
      }
      loader(false)
    });
  };

  useEffect(() => {
    if (id) {
      getMerchants();
      getDetail();
    }
  }, [id]);

  const getMerchants = () => {
    loader(true);
    let url =
      "users/listing?page=1&count=70&search=&role=6540cfa35a7513892be61415&isDeleted=false";
    ApiClient.get(url).then((res) => {
      if (res?.success) {
        loader(false);
        setMerchants(res?.data);
      }
    });
  };

  return (
    <div>
      <div
        className={`modal d-${show}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-2">
            <h3 className="heading clr-text">
                
                  Edit Device
                </h3>
                <button onClick={() => setShow(false)} type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div className="modal-body ">
              <form className="text-start" onSubmit={handleSubmit}>
                <div className="">
                  <div className="product-detail-page">
                    <div className="row">
                      <div className="col-md-6 mb-3 ">
                        <label>
                          Merchant<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          value={device.user_id}
                          onChange={(e) =>
                            handleDeviceChange("user_id", e.target.value)
                          }
                          required
                        >
                          <option value="" disabled>
                            Select Merchant
                          </option>
                          {merchants?.map((item, index) => (
                            <option key={index} value={item?._id || item?.id}>
                              {item?.fullName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>
                          Device Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={device.deviceName}
                          onChange={(e) =>
                            handleDeviceChange("deviceName", e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>
                          Serial no.<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={device.hsn}
                          onChange={(e) =>
                            handleDeviceChange("hsn", e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>
                          Merchant Id<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={device.merchid}
                          onChange={(e) =>
                            handleDeviceChange("merchid", e.target.value)
                          }
                          required
                        />
                      </div>
                      
                    </div>
                    <div className="text-right mt-2">
                      {/* <button
                        type="button"
                        className="btn btn-secondary discard mr-2"
                        onClick={() => setShow(false)}
                      >
                        Back
                      </button> */}
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
