import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";

const Html = ({
  pageChange,
  filters,
  loaging,
  data,
  history,
  deleteItem,
  ShowDeleteModal,
  setShowDeleteModal,
  total = { total },
}) => {
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };
  return (
    <Layout>
         <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Contact </h3>
      </div>

      <>
        <div className="table-responsive table_section">
          <table className="table">
            <thead className="table_head">
              <tr className="heading_row">
                <th scope="col" className="table_data">
                  Customer Name
                </th>
                <th scope="col" className="table_data">
                  Email
                </th>
                <th scope="col" className="table_data">
                  Subject
                </th>
                <th scope="col" className="table_data">
                  Message
                </th>
                <th scope="col" className="table_data">
                      Actions
                    </th>
              </tr>
            </thead>
            <tbody>
              {!loaging &&
                data &&
                data.map((itm, i) => {
                  return (
                    <tr className="data_row">
                      <td
                        onClick={(e) => history(`/contactusdetail/${itm?.id}`)}
                        className="table_dats"
                      >
                        <span className="tableElipsis">
                          {itm?.name || "--"}
                        </span>
                      </td>

                      <td className="table_dats">
                        <span className="test-rev"> {itm?.email || "--"} </span>
                      </td>
                      <td className="table_dats">{itm?.title || "--"}</td>
                      <td className="table_dats">{itm?.message || "--"}</td>
                      <td className="table_dats table-bx">
          <div className="action_icons">  
            <span
              className="edit_icon"
              onClick={() => {
                setDeleteId(itm.id);
                setShowDeleteModal("block");
              }}
            >
              <i class="material-icons delete" title="Delete">
                {" "}
                delete
              </i>
            </span>
            {/* :<></>} */}
          </div>
        </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {!loaging && total == 0 ? (
            <div className="py-3 text-center">No Data</div>
          ) : (
            <></>
          )}
        </div>
      </>

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper">
          <span>
            Show {filters.count} from {total} transactions
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
