import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);

const WaveGraph = ({ data, period }) => {
  let labels = [];
  const totalComission = []; 

  if (period === 'monthly') {
    const monthNames = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    labels = monthNames.slice(1);   
    totalComission.length = 12;  
    totalComission.fill(0); 
    data.forEach(item => {
      totalComission[item._id - 1] = item.totalComission;  
    });
    
  } else if (period === 'yearly') {
    labels = data.map(item => item._id);  
    data.forEach(item => {
      totalComission.push(item.totalComission);
    });
    
  } else if (period === 'weekly') { 
    labels = Array.from({ length: 53 }, (_, i) => `Week ${i + 1}`); 

    totalComission.length = 52; 
    totalComission.fill(0); 
    data.forEach(item => {
      totalComission[item._id - 1] = item.totalComission; 
    });
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Total Commission',
        data: totalComission,
        borderColor: "#82222E",
        backgroundColor: '#82222E',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return labels[index]; // Return the label for the hovered data point
          },
          label: (tooltipItem) => {
            const value = tooltipItem.raw; // Get the value for the hovered data point
            return `Total Commission: $${parseFloat(value).toFixed(2)}`; // Customize the label
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: period.charAt(0).toUpperCase() + period.slice(1),
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Commission',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div> 
      <Line data={chartData} options={options} />
    </div>
  );
};

export default WaveGraph;
