import React from "react";
import Layout from "../../../components/global/layout";
import "react-phone-input-2/lib/style.css";

const Html = ({ form, handleSubmit, setform, history, back, merchants, devices, setDevices }) => {

  const handleDeviceChange = (index, field, value) => {
    const updatedDevices = [...devices];
    updatedDevices[index][field] = value;
    setDevices(updatedDevices);
  };

  const addDevice = () => {
    setDevices([...devices, { hsn: "", merchid: "", user_id: "",deviceName: "" }]);
  };

  const deleteDevice = (index) => {
    const updatedDevices = devices.filter((_, i) => i !== index);
    setDevices(updatedDevices);
  };

   
  

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <h3 className="heading mb-3">
          
            Add Device
          </h3>
          <div className="pprofile1 edit_profile">
            <div className="product-detail-page">
              {devices.map((device, index) => (
                <div key={index} className="form-row">
                  <div className="col-md-6 mb-3">
                    <label>
                      Merchant<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={device.user_id}
                      onChange={(e) => handleDeviceChange(index, "user_id", e.target.value)}
                      required
                    >
                      <option value="" disabled>Select Merchant</option>
                      {merchants?.map((item, index) => (
                        <option key={index} value={item?._id || item?.id}>
                          {item?.fullName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Serial no.<span className="text-danger">*</span>
                    </label>
                    <input
                    placeholder="Serial no."
                      type="text"
                      className="form-control"
                      value={device.hsn}
                      onChange={(e) => handleDeviceChange(index, "hsn", e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Merchant Id<span className="text-danger">*</span>
                    </label>
                    <input
                    placeholder="Merchant Id"
                      type="text"
                      className="form-control"
                      value={device.merchid}
                      onChange={(e) => handleDeviceChange(index, "merchid", e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Device Name<span className="text-danger">*</span>
                    </label>
                    <input
                    placeholder="Device Name"
                      type="text"
                      className="form-control"
                      value={device.deviceName}
                      onChange={(e) => handleDeviceChange(index, "deviceName", e.target.value)}
                      required
                    />
                  </div>
                  {index > 0 && (  
                    <div className="col-md-2 mb-3">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteDevice(index)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
              
              <div className="text-right mt-2">
                <button
                  type="button"
                  className="btn btn-secondary discard mr-2"
                  onClick={() => history("/devices")}
                >
                  Back
                </button>
                <button type="button" className="btn btn-secondary" onClick={addDevice}>
                  Add More
                </button>
                <button type="submit" className="btn btn-primary ml-2">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default Html;
