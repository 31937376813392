import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
// import "./style.scss";
import { Link } from "react-router-dom";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import axios from "axios";
import moment from "moment/moment";
const Html = ({
  tab,
  edit,
  ChangeStatus,
  ShowActiveModal,
  setShowActiveModal,
  //   statusChange,
  pageChange,
  deleteItem,
  setform,
  ShowDeleteModal,
  setShowDeleteModal,
  filters,
  form,
  loaging,
  CatType,
  categoriesdata,
  data,
  isAllow,
  total = { total },
}) => {
  const exportCsv = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}logs?exportToXlsx=true`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `EmployerReports.xlsx`;
    link.click();
  };

  const Navigate = useNavigate();
  const [DeleteId, setDeleteId] = useState("");
  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const ConfirmFunc = () => {
    statusChange(StatusData);
  };

  return (
    <Layout>
      <CommonActiveModal
        show={ShowActiveModal}
        setShow={setShowActiveModal}
        status={StatusData.status}
        confirm={ConfirmFunc}
      />
      <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="hedding">Logs</h3>

        <article className="d-flex">
          <button className="btn btn-primary" onClick={exportCsv}>
            Export
          </button>
        </article>
      </div>

      <>
   
          <div className="table_section table table-responsive mt-3 limitTh">
            <table class="table table-striped table-hover">
              <thead className="table_head border-bottom">
                <tr className="heading_row">
                  <th scope="col" className="table_data">
                    Method
                  </th>
                  <th scope="col" className="table_data">
                    Date
                  </th>
                  <th scope="col" className="table_data">
                    IP Address
                  </th>

                  <th scope="col" className="table_data">
                    url
                  </th>

                  <th scope="col" className="table_data">
                    token
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((itm, index) => (
                    <tr className="data_row">
                      <td
                        className="table_dats "
                        //    onClick={(e) => edit(itm.id)}
                      >
                        <p className="">{itm.method}</p>
                      </td>

                      <td className="table_dats">
                        <p className="">
                          {moment(itm.createdAt).format("DD-MM-YYYY")}
                        </p>
                      </td>
                      <td className="table_dats ">
                        <p className="">{itm.ip}</p>
                      </td>

                      <td className="table_dats">
                        <p className="text-truncate">
                          {itm?.url ? itm?.url : "--"}
                        </p>
                      </td>
                      <td className="table_dats">
                        <p className="text-truncate">
                          {itm?.token ? itm?.token : "--"}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
     
      </>
      {!loaging && total == 0 ? (
        <div className="py-3 text-center">No Data</div>
      ) : (
        <></>
      )}

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper  pagination_end">
          <span>
            Show {filters.count} from {total} Users
          </span>
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Html;
