import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Layout from "../../../components/global/layout";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import methodModel from "../../../methods/methods";
import Pagination from "react-js-pagination";
import moment from "moment";
import CryptoJS from "crypto-js";
import environment from "../../../environment";
import CommonDeleteModal from "../../CommonDelete&ActiveModal/CommonDeleteModal";
import { toast } from "react-toastify";

const CustomerDetail = (p) => {
  const history = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [funds, setFunds] = useState([]);
  const [role, setRole] = useState("");
  const [Customer, setCustomerData] = useState([]);
  const [credsData, setCredsData] = useState([]);
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [total, settotal] = useState();
  const [DeleteId, setDeleteId] = useState("");
  const[userid,setuserid] = useState("")
  const [filter1, setFilter] = useState({
    merchant_id: id,
    page: 1,
    count: 10,
    search: "",
    isDeleted: false,
  });
  const getDetail = () => {
    loader(true);
    ApiClient.get(`user/profile`, { id: id }).then((res) => {
      if (res.success) {
        setData(res.data);
        setRole(res?.data?.role?.name);
        if (res?.data?.role?.name == "Merchant") {
          getCredentials();
          getCustomerData();
        } else {
        }
      }
      loader(false);
    });
  };

  const back = () => {
    history(-1);
  };

  useEffect(() => {
    if (id) {
      getDetail();
      // getFundings();
    }
  }, [id]);
  // const getFundings = () => {
  //   ApiClient.get(`fundings?merchant_id=${id}&mode=sandbox`).then((res) => {
  //     setFunds(res?.data?.fundings || []);
  //   });
  // };
  const getCredentials = () => {
    let url = `creds?user_id=${id}`;
    ApiClient.get(url).then((res) => {
      if (res.success) {
        const data = res.data;
        setCredsData(data);
      }
    });
  };

  const getCustomerData = (p = {}) => {
    let filter = { ...filter1, ...p, merchant_id: id };
    let url = "merchant/transaction/customers";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        const data = res.data;
        setCustomerData(data);
        settotal(res?.total);
      }
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filter1, page: e });
    getCustomerData({ page: e });
  };

  function decrypt(encryptedValue) {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, environment?.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  const edit = (id, userid) => {
    history(`/AddEditMerchantCreds/${userid}/${id}`);
  };

  const Delete = () => {
    deleteItem(DeleteId,userid);
  };

  const deleteItem = (id,userid) => {
    loader(true);
    ApiClient.delete(`creds?id=${id}&user_id=${userid}`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setShowDeleteModal("none"); 
        getCredentials();
        loader(false);
        } else { 
        }
     
    });
  };

  
  return (
    <Layout>
           <CommonDeleteModal
        show={ShowDeleteModal}
        setShow={setShowDeleteModal}
        confirm={Delete}
      />
      <div className="text-right mb-2">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="ViewUser mb-0">
            {role == "Merchant" ? "Merchant" : "User"} Details
          </h3>
        </div>
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {role == "Merchant" ? "Merchant" : "Customer"} Details
          </a>
        </li>
        {role == "Merchant" ? (
          <>
            {/* <li class="nav-item">
              <a
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Funds
              </a>
            </li> */}
            <li class="nav-item">
              <a
                class="nav-link"
                id="contact-tab"
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Credentials
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="customer-tab"
                data-toggle="tab"
                href="#customer"
                role="tab"
                aria-controls="customer"
                aria-selected="false"
              >
                Customers
              </a>
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
      <div class="tab-content mt-4" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          {" "}
          <div className="row">
            <div className="sideclass col-md-12">
              <div className="pprofile1 edit_profile">
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label className="profileheddingcls mb-1">Name</label>
                    <div className="profiledetailsclsams">
                      {data && data.fullName}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="profileheddingcls mb-1">Mobile No</label>
                    <div className="profiledetailsclsams">
                      ({data && data?.dialCode}) {data?.mobileNo}
                    </div>
                  </div>

                  {role == "Merchant" ? (
                    <div className="col-md-6 mb-3">
                      <label>Merchant Fee (%)</label>
                      <div className="profiledetailsclsams">
                        {(data && data.transaction_rate) || "--"}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6 mb-3">
                    <label className="profileheddingcls mb-1">Address</label>
                    <div className="profiledetailsclsams">
                      {data?.address || "--"}
                    </div>
                  </div>
                  {role == "Merchant" ? (
                    <div className="col-md-6 mb-3">
                      <label className="profileheddingcls mb-1">
                        Business Name
                      </label>
                      <div className="profiledetailsclsams">
                        {data?.companyName || "--"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-6 mb-3">
                    <label className="profileheddingcls mb-1">Email</label>
                    <div className="profiledetailsclsams">
                      {(data && data.email) || "--"}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="profileheddingcls mb-1">Role</label>
                    <div className="profiledetailsclsams">
                      {data?.role?.name || "--"}
                    </div>
                  </div>
                  {data?.role?.name == "Merchant" ? (
                    <div className="col-md-6 mb-3">
                      <label className="profileheddingcls mb-1">
                        Merchant Link
                      </label>
                      <div className="profiledetailsclsams">
                        <a
                          href={`https://lux.jcsoftwaresolution.in/products/merchant/${data?.slug}`}
                        >
                          {`https://lux.jcsoftwaresolution.in/products/merchant/${data?.slug}`}
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 mb-3">
                    <label className="profileheddingcls mb-1">Image</label>
                    <div>
                      <div className="imagethumbWrapper">
                        <img
                          src={methodModel.userImg(data && data.image)}
                          className="uploadimage"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-right col-12">
                    <button
                      to="/users"
                      onClick={back}
                      className="btn btn-secondary discard"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          {/* {role == "Merchant" ? (
            <section className="">
              <div className="container">
                <div className="row">
                  <div className="col-12 ">
                    <div className="d-flex justify-content-between align-items-center ">
                      <h3 className="m-0 hedding text-center my-2">Funds</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive table_section">
                      <table className="table">
                        <thead className="table_head">
                          <tr className="heading_row">
                            <th className="table_data">Net Sales</th>
                            <th className="table_data">Total Funding</th>
                            <th className="table_data">Fee</th>
                            <th className="table_data">Third party </th>
                            <th className="table_data">Reversal</th>
                            <th className="table_data">Inter Change Fee</th>
                            <th
                              onClick={() => {
                                history("/adjustments");
                              }}
                              className="table_data"
                            >
                              Adjustment
                            </th>
                            <th className="table_data">Service Charge</th>
                            <th className="table_data">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {funds?.length > 0 ? (
                            funds?.map((data, index) => (
                              <tr key={index}>
                                <td>{data.netsales}</td>
                                <td>{data?.totalfunding}</td>
                                <td>
                                  <p className="line-break">{data.fee}</p>
                                </td>
                                <td>
                                  <p className="line-break">
                                    {data.thirdparty}
                                  </p>
                                </td>
                                <td>
                                  <p className="line-break">{data.reversal}</p>
                                </td>
                                <td>
                                  <p className="line-break">
                                    {data.interchangefee}
                                  </p>
                                </td>
                                <td>
                                  <p className="line-break">
                                    {data.adjustment}
                                  </p>
                                </td>
                                <td>
                                  <p className="line-break">
                                    {data.servicecharge}
                                  </p>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="credential_data">
                              <td className="text-center" colspan="9">
                                No Funds
                              </td>
                            </tr>
                          )}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )} */}
        </div>
        <div
          class="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-12 ">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h3 className="m-0 hedding text-center my-2">
                      Credentials
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive table_section">
                    <table className="table">
                      <thead className="table_head">
                        <tr className="heading_row">
                          <th scope="col" className="table_data">
                            User Name
                          </th>
                          <th scope="col" className="table_data">
                            Mode
                          </th>
                          <th scope="col" className="table_data">
                            Site
                          </th>
                          <th scope="col" className="table_data">
                            Currency
                          </th>
                          <th scope="col" className="table_data">
                            Api User Name
                          </th>
                          <th scope="col" className="table_data">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {credsData?.length > 0 ? (
                          credsData.map((itm, i) => {
                            return (
                              <tr className="data_row">
                                <td
                                  className="table_dats"
                                  style={{ textTransform: "lowercase" }}
                                >
                                  {itm.fullName || "N/A"}
                                </td>
                                <td
                                  className="table_dats"
                                  style={{ textTransform: "lowercase" }}
                                >
                                  {itm.mode}
                                </td>
                                <td
                                  className="table_dats"
                                  style={{ textTransform: "lowercase" }}
                                >
                                  {decrypt(itm.site)}
                                </td>
                                <td className="table_dats">
                                  {decrypt(itm?.currency)}
                                </td>
                                <td className="table_dats">
                                  {decrypt(itm?.authuname)}
                                </td>

                                {/* dropdown */}
                                <td className="table_dats">
                                  <div className="action_icons">
                                    <>
                                      <a
                                        className="edit_icon"
                                        title="Edit"
                                        onClick={(e) =>
                                          edit(itm.id, itm?.user_id)
                                        }
                                      >
                                        <i
                                          class="material-icons edit"
                                          title="Edit"
                                        >
                                          edit
                                        </i>
                                      </a>
                                    </>

                                    <>
                                      <span
                                        className="edit_icon"
                                        title="Delete"
                                        onClick={() => {
                                          setDeleteId(itm.id);
                                          setuserid(itm?.user_id);
                                          setShowDeleteModal("block");
                                        }}
                                      >
                                        <i
                                          class="material-icons delete"
                                          title="Delete"
                                        >
                                          {" "}
                                          delete
                                        </i>
                                      </span>
                                    </>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="credential_data">
                            <td className="text-center" colspan="9">
                              No Credentials added
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          class="tab-pane fade"
          id="customer"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div className="table-responsive">
            <div className="table_section ">
              <table class="table table-striped table-hover">
                <thead className="table_head">
                  <tr className="heading_row">
                    <th scope="col" className="table_data">
                      S.NO
                    </th>

                    <th scope="col" className="table_data">
                      Email
                    </th>
                    <th scope="col" className="table_data">
                      Name
                    </th>

                    <th scope="col" className="table_data">
                      mobileNo
                    </th>

                    <th scope="col" className="table_data">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Customer?.length > 0 ? (
                    Customer.map((data, index) => (
                      <tr className="data_row">
                        <td className="table_dats">{index + 1}</td>
                        <td className="table_datsd">{data?.email}</td>
                        {/* <td className="table_dats" onClick={()=>history(`/transactionDeatil/${data?.id}`)}>{data?.merchant_name || '--'}</td> */}
                        <td className="table_dats">{data?.fullName}</td>
                        <td className="table_dats">
                          {data?.mobileNo ? data?.mobileNo : "--"}
                        </td>
                        <td className="table_dats">
                          {moment(data?.createdAt).format("DD-MM-YY")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p className="text-center">No Data Found </p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {total > filter1.count ? (
            <div className="paginationWrapper  pagination_end">
              <span>
                Show {filter1.count} from {total} Users
              </span>
              <Pagination
                activePage={filter1.page}
                itemClassPrev="back_page"
                itemClassNext="next_page"
                itemsCountPerPage={filter1.count}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={pageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CustomerDetail;
