import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import loader from "../../methods/loader";
import userTableModel from "../../models/table.model";
import Html from "./html";
import { userType } from "../../models/type.model";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import environment from "../../environment";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';

const Devices = (p) => {
  const { role } = useParams();
  const user = useSelector((state) => state.user);
  const userid = localStorage?.getItem("userid");
  const [DataLength, setDataLength] = useState(0);
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [showEditModal, setshowEditModal] = useState("none");
  const searchState = useSelector((state) => state.search);
  const[hide,setHide] = useState(false)
  const [filters, setFilter] = useState({
    page: 1,
    count: 20,
    search: "",
    role: role || "",
    isDeleted: false,
  })
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("list");
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const [tableCols, setTableCols] = useState([]);
  const [form, setform] = useState(userType);
  const [roles, setRoles] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data, role });
      getData({ search: searchState.data, role, page: 1 });
    }
  }, [searchState.data,hide]);

  useEffect(() => {
    if (user && user._id) {
      setFilter({ ...filters, search: searchState.data, role });
      getData({ search: searchState.data, role, page: 1 });
    }
  }, []);

  const uTableCols = () => {
    let exp = [];
    if (tableCols) exp = tableCols;
    let value = [];
    userTableModel.list.map((itm) => {
      if (itm != exp.find((it) => it.key == itm.key)) {
        value.push(itm);
      }
    });
    return value;
  };

  const addCol = (itm) => {
    setTableCols([...tableCols, itm]);
  };

  const removeCol = (index) => {
    let exp = tableCols;
    exp.splice(index, 1);
    setTableCols([...exp]);
  };

  const getData = () => {
    setLoader(true);
    let url = `devices`;
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        const data = res.data;
        setData(data);
      }
      setLoader(false);
    });
  };

  const clear = () => {
    setFilter({ ...filters, search: "" });
    getData({ search: "" });
  };

  const deleteItem = (id,userid) => {
    loader(true);
    ApiClient.delete(`device/delete?id=${id}`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setShowDeleteModal("none");
        if (DataLength == 0) {
          setFilter({ ...filters, search: "", page: 1 });
          getData({ search: "", page: 1 });
        } else {
          clear();
        }
      }
      loader(false);
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  }; 

  const openModal = (itm) => {
    let extra = new Date().getTime();
    setform({ ...itm, extra, role });
    document.getElementById("openuserModal").click();
  };

  const add = () => {
    let url = `/addeditdevices`; 
    history(url);
  };

  const tabChange = (tab) => {
    setTab(tab);
  };


  const reset = () => {
    let filter = {
      status: "",
      role: "",
      page: 1,
    };
    setFilter({ ...filters, ...filter });
    getData({ ...filter });
    // dispatch(search_success(''))
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    getData({ ...p, page: filters?.page });
  };

  const sorting = (key, i) => {
    filter({ sortBy: key, sorder: i });
  };

  const isAllow = (key = "") => {
    // let permissions=user.role?.permissions
    // let value=permissions?.[key]
    // if(user?.role?.id==environment.adminRoleId) value=true

    return true;
  }; 

  return (
    <>
      <Html
        user={user}
        isAllow={isAllow}
        tabChange={tabChange}
        tab={tab}
        reset={reset}
        add={add}
        openModal={openModal}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem}
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters} 
        setshowEditModal={setshowEditModal}
        showEditModal={showEditModal}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        ShowDeleteModal={ShowDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        total={total}
        sorting={sorting}
        setHide={setHide}
        hide={hide}
      />
    </>
  );
};

export default Devices;
