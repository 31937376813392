import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import loader from "../methods/loader";
import ApiClient from "../methods/api/apiClient";

export default function RejectionModals({
  show,
  setShow,
  reason,
  setReason,
  rejectData,
  clear,
}) {
  const [ShowModal, setShowModal] = useState("none");
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const rejectRefund = () => {
    if (!reason) {
      toast?.error("Reason is required");
      return;
    } else {
      loader(true);
      ApiClient.put(`refund-request`, {
        refundStatus: "rejected",
        transaction_id: rejectData._id || rejectData?.id,
        refundRejectReason: reason,
      }).then((res) => {
        if (res.success) {
          setShow(false);
          clear()
        }
        loader(false);
      });
    }
  };
  return (
    <div>
      <div
        class={`modal delete-modal  d-${show}`}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content p-0">
            <div class="modal-header">
              <h3 className="hedding">Reject refund</h3>
              <button
                type="button"
                class="close"
                onClick={(e) => setShow("none")}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
              <p className=" text-reason">Reason</p>
            <textarea className="w-100 input-set"
              type="text"
              placeholder="reason"
              onChange={(e) => {
                setReason(e?.target?.value);
              }}
              value={reason}
            />
              </div>
           

            <div class="text-end mt-3 ">
              <button
                type="button"
                onClick={(e) => rejectRefund()}
                class="btn btn-primary"
              >
                Reject
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
