import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
// import methodModel from '../../methods/methods'
import datepipeModel from "../../models/datepipemodel";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from "moment";
export default function Details() {
  const [filters, setFilter] = useState({
    page: 1,
    count: 10,
    search: "",
    catType: "",
    interval: "",
    currencyId: "",
    role: "6540cfa35a7513892be61415",
  });
  let user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const history = useNavigate();

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({
        ...filters,
        search: searchState.data,
        role: "6540cfa35a7513892be61415",
      });
      getData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

 
  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };


  const getData = (p = {}) => {
    setLoader(true)
    let filter = { ...filters, ...p };

    // let filter = user?.role_name == 'Customer' || user?.role_name == 'Guest' ? { ...filters, ...p,user_id:user._id } : { ...filters, ...p,merchant_id:user._id }
    ApiClient.get('order/all', filter).then(res => {
        if (res.success) {
            setData(res.data)
            setTotal(res.total)
        }
        setLoader(false)
    })
}


  return (
    <Layout>
      <div>
        <h3 className="hedding">Merchant's Customers</h3>
        <div className="table-responsive">
          <div className="table_section ">
            <table class="table table-striped table-hover">
              <thead className="table_head">
                <tr className="heading_row">
                  <th scope="col" className="table_data">
                    S.NO
                  </th>

                  <th scope="col" className="table_data">
                    Email
                  </th>
                  <th scope="col" className="table_data">
                    Name
                  </th>

                  <th scope="col" className="table_data">
                    Date
                  </th>
                </tr>
              </thead>
              {!loaging && (
                <tbody>
                  {data.map((data, index) => (
                    <tr className="data_row">
                      <td className="table_dats">{index + 1}</td>
                      <td
                        className="table_datsd"
                        onClick={() => history("/details/" + data?.roleId)}
                      >
                        {data?.email}
                      </td>
                      {/* <td className="table_dats" onClick={()=>history(`/transactionDeatil/${data?.id}`)}>{data?.merchant_name || '--'}</td> */}
                      <td className="table_dats">{data?.role?.name}</td>

                      <td className="table_dats">
                        {moment(data?.createdAt).format("DD-MM-YY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {!loaging && total == 0 ? (
        <div className="py-3 text-center">No Data</div>
      ) : (
        <></>
      )}

      {!loaging && total > filters.count ? (
        <div className="paginationWrapper  pagination_end">
          <span>
            Show {filters.count} from {total} Users
          </span>
          <Pagination
            activePage={filters.page}
            itemClassPrev="back_page"
            itemClassNext="next_page"
            itemsCountPerPage={filters.count}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={pageChange}
          />
        </div>
      ) : (
        <></>
      )}

      {loaging ? (
        <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
}
