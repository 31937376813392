import React, { useEffect, useState } from "react";
import statusModel from "../../../models/status.model";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import ImageUpload from "../../../components/common/ImageUpload";
import { Editor } from "@tinymce/tinymce-react";

export default function AddEditFaq() {
  const { id } = useParams();
  const user = useSelector((state) => state.user); 
  const Navigate = useNavigate();
  const [form, setform] = useState({
    id: "",
    question: "",
    answer: "",
    category: "",
    // video:""
  }); 


  const [submitted, setsubmitted] = useState(false);
  const [image, setImage] = useState([]);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setsubmitted(true);
    if(form.answer=="")return false;
    loader(true);
    let method = "post";
    const payload = form;
    let url = "faq";
    if (id) {
      method = "put";
      payload.id = id;
      url="faq/update"
    } else {
      delete payload.id;
    }
    ApiClient.allApi(url, payload, method).then((result) => {
      if (result) {
       ( toast.success(" FAQ is added Successfully"));
        Navigate("/faq");
      }
      loader(false);
    });
  }; 

  const [categoriesdata,setCategoriesdata]=useState([])
  const GetcategoriesTypes=()=>{
      loader(true);
      ApiClient.get(`categories/listing?status=active&catType=faq`).then(res=>{
if(res.success){


  setCategoriesdata(res.data);
}
loader(false)
      })
  }

  useEffect(() => {
    GetcategoriesTypes()
    if (id) {
      ApiClient.get(`faq/detail?id=${id}`).then((result) => {
        if (result.success) {
          const newdata = result.data; 
          setform({
            id: newdata?.id,
            question: newdata?.question,
            answer: newdata?.answer,
            category: newdata?.category?._id,
            video:newdata?.video
          });
        }
      });
    }
  }, []);


  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user?.role?.id == environment.adminRoleId) value = true;
    return value;
  }; 
  return (
    <Layout>
      <form onSubmit={(e) => handleSubmit(e)}>
      <h3 className="ViewUser">{id ? "Edit" : "Add"} FAQs</h3>
        <div className="pprofile1 edit_profile">
        
          <div className="form-row">
            <div className="col-md-12 text-right ">
              {/* <i class="fa fa-trash text-danger" aria-hidden="true"></i> */}
            </div>
            <div className="col-md-6 mb-3">
              <label>
              Question<span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={form.question}
                onChange={(e) => setform({ ...form, question: e.target.value })}
                required
              />
            </div>

            <div className="col-md-6 mb-3">
                <label>
                Category<span className="star">*</span>
                </label>
          <select className='form-control' required onChange={e=>setform({...form,category:e.target.value})} value={form.category} >
            <option value="">Select  category</option>
            {categoriesdata.map((item,index)=>(
                <option className='text-capitalize' value={item.id}>{item.name}</option>
            ))}
          </select>
              </div>  

            {/* <div className="col-md-12 mb-3">
              <label>
              Answer<span className="star">*</span>
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={form.answer}
                onChange={(e) =>
                  setform({ ...form, answer: e.target.value })
                }
              ></textarea>
            </div> */}



                            {/* edior */}
                            <div className="col-md-12 mt-3">
                            <div className="form-group">
                              <label for="exampleFormControlTextarea1">
                              answer <span className="text-danger">*</span>
                              </label>
                              <Editor
                              apiKey='e9b46x5ebse3zswyqxc5gpl8b5zzduu2ziq9r75c2s91ytpe'
                              required="required"
                                value={form.answer}
                                onEditorChange={(newValue, editor) => {
                                  setform({
                                    ...form,
                                    answer: newValue,
                                  });
                                }}
                                init={{
                                  height: "200px",
                                  selector: "textarea",
                                  plugins: "table code colorpicker textcolor",
                                  toolbar:
                                    "table code bold italic alignleft aligncenter alignright alignjustify | outdent indent ",
                                  toolbar_mode: "floating",
                                }}
                              />
                        {submitted&&form.answer==""?<p className="text-danger ml-3 mt-1">Answer is required</p>:null}
                            </div>
                          </div>
                          {/* end */}
                          {/* <div className="col-md-6 mt-3">
                    <label className="mb-2"> video</label>
                    {!form.video||form.video==""?<div  className="profile_btn_portfolio d- ">
                      <label className={`add_portfolio edit ml-3`}>
                        <input id="bannerImage" accept='.mp4' type="file" onChange={e=>VideoUpload(e)}  className="d-none" />
                        <span  className="add_portfolio">
                          <i  className="material-icons add_port">add</i>
                          </span>
                          </label>
                          </div>:<div><video width={300} height={150} src={`${environment.api+"images/videos/"+form.video}`} controls/><i className='fa fa-trash text-danger video_tarsh' style={{marginTop:"-100px"}} onClick={e=>{setform({...form,video:""})}}></i></div>}

                  </div> */}



       
          </div>

          <div className="text-right">
            <button
              type="button"
              className="btn btn-secondary discard mr-2"
              onClick={(e) => Navigate(-1)}
            >
              Back
            </button>
            {!id || (id && isAllow("editSkillType")) ? (
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            ) : null}
          </div>
        </div>
      </form>
    </Layout>
  );
}
