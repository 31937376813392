import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
// import Pagination from "react-js-pagination";
import Pagination from "react-pagination-js";
import './style.scss';
import AddEditContent from './AddEditContent';
import ViewContent from './ViewContent';
import environment from '../../environment';
import loader from '../../methods/loader';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import SelectDropdown from '../../components/common/SelectDropdown';


const ContentManagement = (p) => {
    let user = useSelector(state => state.user);
    const [Update, setUpdate] = useState(new Date())
    const [ShowActiveModal, setShowActiveModal] = useState("none");
    const [StatusData, setStatusData] = useState({});
    const [filters, setFilter] = useState({ page: 1, count: 10, search: '', status: "", category: "", subCategory: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoader] = useState(false);
    const [type, setType] = useState()
    const [form, setform] = useState({ subCategory: "", category: "", keyword: [], description: '', tiny_description: '' })
    const searchState = useSelector((state) => state.search);
    const [Details, setDetails] = useState({ categorydetails: {}, subCategorydetails: {} });

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])

    const getData = (p = {}) => {
        // setLoader(true)
        loader(true);
        let filter = { ...filters, ...p }
        ApiClient.get('cms/listing', filter).then(res => {
            if (res.success) {
                setData(res.data)
                setTotal(res.total)
            }
            // setLoader(false)
            loader(false);
        })
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const openModal = (itm = '') => {
        setUpdate(new Date())
        setform({ title: '', slug: '', description: '', meta_title: '', meta_description: '', keyword: '', category: '' })
        if (itm) {
            setform({
                ...form, ...itm, title: itm.title, slug: itm.slug, description: itm.description, meta_title: itm.meta_title, meta_description: itm.meta_description, keyword: itm.keyword, category: itm.category, subCategory: itm.subCategory
            })
        }
        // "category":null,
        // "subCategory":null,
        document.getElementById('openContentModal').click()
    }

    const openViewModal = (itm = '') => {
        setform({ ...form, ...itm })
        document.getElementById("openViewContantModal").click()
    }




    const modalClosed = () => {
        setFilter({ ...filters, page: 1 })
        getData()
        setform({ subCategory: "", category: "", keyword: [], description: '', tiny_description: '' })
    }

    const isAllow = (key = '') => {
        let permissions = user.role?.permissions
        let value = permissions?.[key]
        if (user.role.id == environment.adminRoleId) value = true

        return value
    }

    const statusChange = () => {
        let status = 'active'
        if (StatusData.status == 'active') status = 'deactive'

        // if(window.confirm(`Do you want to ${status=='active'?'Activate':'Deactivate'} this Content`)){
        loader(true)
        ApiClient.put(`cms/status/change`, { status, id: StatusData.id }).then(res => {
            if (res.success) {
                getData()
                toast.success(` Content ${status == 'active' ? 'Activated' : 'Deactivated'} Successfully`)
                setShowActiveModal("none")
            }
            loader(false)
        })
        // }
    }
    const [ShowDeleteModal, setShowDeleteModal] = useState("none");
    const [DeleteId, setDeleteId] = useState("")
    const Deletecms = () => {
        loader(true);
        ApiClient.delete(`cms/delete?id=${DeleteId}`).then(res => {
            if (res.success) {
                toast.success(res.message);
                getData()
                setShowDeleteModal("none")
            }
        })
    }

    const [CategoriesData, setCategoriesData] = useState([]);
    const GetCategories = () => {
        ApiClient.get(`categories/listing?status=active&type=main&catType=page`).then(res => {
            if (res.success) {
                const Categoryarray = []
                res.data && res.data.map((item) => { Categoryarray.push({ id: item._id, name: item.name }) })
                setCategoriesData(Categoryarray);
            }

        })
    }

    const [SubCategoriesData, setSubCategoriesData] = useState([]);
    const GetSubCategories = (e) => {
        ApiClient.get(`categories/listing?type=child&parentCategory=${e}`).then(res => {
            if (res.success) {
                const subcategoryarray = [];
                res.data && res.data.map((item) => {
                    subcategoryarray.push({ id: item._id, name: item.name })
                })
                setSubCategoriesData(subcategoryarray);
            }
        })
    }
    useEffect(() => {
        GetCategories()
        GetSubCategories("")
    }, [])

    useEffect(() => {
        GetSubCategories(filters.category)
    }, [filters.category])
    return (
        <Layout>
            <CommonActiveModal show={ShowActiveModal} setShow={setShowActiveModal} status={StatusData.status} confirm={statusChange} />
            <CommonDeleteModal show={ShowDeleteModal} confirm={Deletecms} setShow={setShowDeleteModal} />

           <div className='d-flex gap-1 align-items-center justify-content-between mb-3 flex-wrap'>
           <h3 className="hedding">
                Managing Content
            </h3>

            <div className='d-flex justify-content-end flex-wrap gap-1' >
                <div>
                    <SelectDropdown
                        intialValue={filters.category}
                        options={CategoriesData}
                        displayValue='name'
                        placeholder='Select Category'
                        result={e => { setFilter({ ...filters, category: e.value }); if (e.value == "") { setFilter({ ...filters, subCategory: "", category: "" }); getData({ category: e.value, subCategory: "" }) } else { getData({ category: e.value }) } }}
                    />
                </div>                  
                <div>
                    <SelectDropdown
                        intialValue={filters.subCategory}
                        options={SubCategoriesData}
                        disabled={filters.category == ""||SubCategoriesData.length<1}
                        displayValue='name'
                        placeholder='Select SubCategory'
                        result={e => { setFilter({ ...filters, subCategory: e.value }); getData({ subCategory: e.value }) }}
                    />
                </div>
                <div>
                    <SelectDropdown
                        intialValue={filters.status}
                        options={[{ id: "active", name: "Active" }, { id: "deactive", name: "InActive" }]}
                        displayValue='name'
                        result={e => { setFilter({ ...filters, status: e.value }); getData({ status: e.value }) }}
                    />
                </div>
                <button className='btn btn-primary' onClick={e => openModal()} >Add Content Management</button>
            </div>
           </div>

            <div className="table-responsive table_section table_bx">
                <table className="table table-striped table-hover">
                    <thead className="table_head">
                        <tr className="heading_row">
                            {/* <th class="table_data">Page Name</th> */}
                            <th class="table_data" scope="col">Title</th>
                            <th className='table_data'>Category</th>
                            <th class="table_data" scope="col">Meta Description</th>
                            <th class="table_data" scope='col'>Status</th>
                            <th class="table_data" scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((itm, i) => {
                            return <tr key={i}>
                                {/* <td><span className='tuncketcls text-capitalize'>{itm.slug}</span></td> */}
                                <td><span className=' tuncketcls tableAlter'>{itm.title}</span></td>
                                <td>{itm?.categoryDetail&&itm?.categoryDetail?.name}</td>
                                <td><span className=' tuncketcls'>{itm.meta_description}</span></td>
                                <td className='table_dats'>   <div className={` `} >
                                    <span className='contract table-bx'>
                                        {itm.status == 'deactive' ? <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={false} /> : <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={true} />}
                                    </span>
                                </div></td>
                                <td className="nowrap">
                                    <a className="linkclass mx-2" onClick={() => openViewModal(itm)} title="View"><i className="fa fa-eye" aria-hidden="true"></i></a>{isAllow('editContent') ? <>
                                        <a className="linkclass mx-2" onClick={() => openModal(itm)} title="Edit"><i className="fas fa-pen"></i></a></> : null}

                                    <a className="linkclass mx-2" onClick={() => { setShowDeleteModal("block"); setDeleteId(itm.id) }} title="Delete"><i className="fa fa-trash text-danger" aria-hidden="true"></i></a>

                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>

            {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

            {!loading && total > filters.count ? <div className='pagination_end'>
                {/* <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.count}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={pageChange}
                /> */}
                <Pagination
                    currentPage={filters.page}
                    totalSize={total}   
                    sizePerPage={filters.count}
                    changeCurrentPage={pageChange}
                />
            </div> : <></>}
            <AddEditContent form={form} Update={Update} setform={setform} modalClosed={modalClosed} />
            <ViewContent form={form} setform={setform} modalClosed={modalClosed} />
        </Layout>
    );
};







export default ContentManagement;
