import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import environment from '../../../environment';

const FaqDetail = (p) => {
    const history = useNavigate()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`faq/detail`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    };

    const back = () => {
        history(-1)
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
    }, [id, userId])

    return (
        <Layout>



{/* 
            <div className="d-flex justify-content-between align-items-center">
             
                <a to="/blog" onClick={back}>  <i className="fa fa-arrow-left mr-2" title='Back' aria-hidden="true"></i>Back</a>
             <button onClick={e=>history(`/AddEditfaq/${id}`)} className='btn btn-primary d-flex align-items-center'>   <i  style={{cursor:"pointer"}} class="material-icons edit mr-1 text-white" title="Edit">edit</i>Edit</button>
              
            </div> */}
            <h3 className="Profilehedding mt-3 ">
                    FAQs Detail
                    </h3>
<div className='pprofile1 edit_profile'>
    
            <div className="row">
                <div className="sideclass col-md-12 ">
                  

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls mb-1">Question</label>
                            <div className=' profiledetailsclsams'>{data && data.question}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls mb-1">Category</label>
                            <div className='  profiledetailsclsams'>{data&&data.category.name}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="profileheddingcls mb-1">Answer</label>
                            {/* <div className='profiledetailscls'>{data && data.answer}</div> */}
                            <div className='description_faqdetail profiledetailsclsams h-auto' dangerouslySetInnerHTML={{__html: data && data.answer}}></div>  
                        </div> 
                        {/* {data?.video&&data?.video!=""?
           <div className="col-md-12 mb-3">
                <label className="profileheddingcls mb-1">Video</label>
                <div>
                  <div className="imagethumbWrapper">
                  <video width={300} height={150} src={`${environment.api+"images/videos/"+data?.video}`} controls/>
                  </div>

            
                </div>
              </div>:null} */}

<div class="col-12 text-end"><button to="/blog" onClick={back} type="button" class="btn btn-secondary mr-2 discard">Back</button><button onClick={e=>history(`/AddEditfaq/${id}`)} type="submit" class="btn btn-primary">Edit</button></div>
                                             
                                                             
            
                    </div>
                </div>
            </div>
            </div>


        </Layout>

    );
};

export default FaqDetail;
